//用户请求模块

import request from "@/utils/request.js"

//获取账号信息
export const getUserInfo_api1=params=>{
return  request({
  method: 'get',
  url: 'api/v1/store/info',
  params,
});
} 

//修改密码
export const changePwd_api1=data=>{
  return  request({
    method: 'post',
    url: 'api/v1/store/updatePassword',
    data: data
  });
  } 


