<template>
  <div class="change-pwd">
    <h4>账号设置</h4>
    <div class="change-pwd-content">
      <el-form
        :model="ruleForm"
        ref="passForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="旧密码"
          prop="oldPass"
          :rules="[{ required: true, message: '旧密码不能为空' }]"
        >
          <el-input
            type="password"
            v-model="ruleForm.oldPass"
            autocomplete="off"
            placeholder="请输入旧密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="修改密码" prop="pass" :rules="passRules">
          <el-input
            type="password"
            v-model="ruleForm.pass"
            autocomplete="off"
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass" :rules="checkPassRules">
          <el-input
            type="password"
            v-model="ruleForm.checkPass"
            autocomplete="off"
            placeholder="请再次输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            style="background: #cd0550; border: 0"
            type="primary"
            size="small"
            @click="changePwd"
            >提交</el-button
          >
          <el-button size="small" @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {changePwd_api} from "@/api/user.js"
import {changePwd_api1} from "@/api/store/user.js"
import local from "@/utils/local.js"
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value.search(/^[a-zA-Z0-9]{6,20}$/)) {
        callback(new Error("密码由 6到20位（字母，数字）组成"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        pass: "",
        checkPass: "",
        oldPass: "",
      },
      checkPassRules: [
        { required: true, validator: validatePass2, trigger: "blur" },
      ],
      passRules: [{ required: true, validator: validatePass, trigger: "blur" }],
    };
  },
  methods:{
    //重置
      resetForm() {
        this.ruleForm={}
      },
      //修改密码
      async changePwd(){
        let isChain=local.get("user")
        if(isChain==1){
          let res=await changePwd_api({
            old_password:this.ruleForm.oldPass,
            password:this.ruleForm.pass,
            password_confirmation:this.ruleForm.checkPass
          })
          if(res.data.code==0){
            this.$message({
              message: '恭喜你，修改密码成功!',
              type: 'success'
            })
            setTimeout(()=>{
              this.$router.push('/')
            },2000)
          }else{
            this.$message.error(res.data.msg);
          }
        }else{
          let res=await changePwd_api1({
            old_password:this.ruleForm.oldPass,
            password:this.ruleForm.pass,
            password_confirmation:this.ruleForm.checkPass
          })
          if(res.data.code==0){
            this.$message({
              message: '恭喜你，修改密码成功!',
              type: 'success'
            });
            setTimeout(()=>{
              this.$router.push('/')
            },2000)
          }else{
            this.$message.error(res.data.msg);
          }
        }
      }
  }
}
</script>

<style lang="less" scoped>
.change-pwd {
    width: 100%;
    height: 100%;
  background: #eee;
  padding: 30px;
  box-sizing: border-box;
}
.change-pwd-content {
  margin-top: 30px;
  height: 300px;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/.el-form-item__label {
  text-align: left;
  
}
/deep/.el-form-item__content {
  display: flex;
  justify-content: center;
}
button{
    font-size: 16px !important;
  font-weight: bold !important;
}
</style>